<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col cols="12" lg="12">
            <div class="d-inline-flex align-center">
              <h3 class="mr-2 mb-0">
                태그 타겟: {{ rs3.isTagFilter ? '활성' : '비활성' }}
              </h3>
              <v-switch
                v-model="rs3.isTagFilter"
                class="ma-0"
                hide-details
                color="primary"
                inset
              />
            </div>
          </v-col>
          <v-col cols="12" v-if="rs3.isTagFilter">
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col>
                <h3>타겟 방식</h3>
                <validation-provider v-slot="{ errors }" rules="required">
                  <v-radio-group
                    v-model="rs3.targetTagsMode"
                    row
                    class="d-inline-block mt-0"
                    :error-messages="errors"
                  >
                    <v-radio
                      :value="mode.value"
                      class="ml-3"
                      v-for="(mode, index) in TargetTagsModeList"
                      :key="index"
                    >
                      <template v-slot:label>
                        <span>
                          {{ mode.text }}
                          <InformationHover
                            :title="mode.text"
                            :subTitle="mode.comment"
                          />
                        </span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>타겟 조회 기간</h3>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{
                    required: true
                  }"
                >
                  <v-slider
                    v-model="rs3.targetTagsRange"
                    :thumb-size="45"
                    :min="10"
                    :max="120"
                    type="number"
                    step="10"
                    color="success"
                    ticks="always"
                    tick-size="4"
                    style="padding-left:120px"
                    thumb-label="always"
                    :error-messages="errors"
                    @change="changeTargetTag"
                    :disabled="targetTagLoading"
                  >
                    <template v-slot:thumb-label="{ value }">
                      <span class="subtitle-1">{{ value }}</span
                      >일
                    </template>
                  </v-slider>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>타겟 클릭 필터</h3>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{
                    required: true
                  }"
                >
                  <v-slider
                    v-model="rs3.targetTagsClick"
                    :thumb-size="45"
                    :min="1"
                    :max="10"
                    type="number"
                    step="1"
                    color="info"
                    ticks="always"
                    tick-size="4"
                    style="padding-left:120px"
                    thumb-label="always"
                    :error-messages="errors"
                    :disabled="targetTagLoading"
                  >
                    <template v-slot:thumb-label="{ value }">
                      <span class="subtitle-1">{{ value }}</span
                      >회
                    </template>
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            v-on="{ ...onTooltip }"
                            icon
                            @click="
                              clickDetailTableBottomSheet = !clickDetailTableBottomSheet
                            "
                            ><v-icon>mdi-dots-vertical</v-icon></v-btn
                          >
                        </template>
                        <span>상세보기</span>
                      </v-tooltip>
                    </template>
                  </v-slider>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>타겟 필터 유형</h3>
                <validation-provider v-slot="{ errors }" rules="required">
                  <v-radio-group
                    v-model="rs3.targetTagsType"
                    class="mt-0"
                    :error-messages="errors"
                    @change="setTargetTagsCount"
                    row
                  >
                    <v-radio
                      :value="type.value"
                      class="ml-3"
                      v-for="(type, index) in TargetTagsTypeList"
                      :key="index"
                    >
                      <template v-slot:label>
                        <div>
                          <span>
                            {{ type.text }}
                            <InformationHover
                              :title="type.text"
                              :subTitle="type.comment"
                            />
                          </span>
                          <div class="d-block">
                            <v-chip v-if="rs3.isTagFilter" label outlined>
                              <span class="mr-2">
                                예상 도달 수:
                              </span>
                              <v-progress-circular
                                v-if="targetTagLoading"
                                indeterminate
                                :width="3"
                                :size="20"
                                color="primary"
                              ></v-progress-circular>
                              <div v-else>
                                <div v-if="type.value === 'A'">
                                  <span class="title font-italic">
                                    {{ targetTagsAndCount.toLocaleString() }}
                                  </span>
                                  <span class="subtitle-1">{{ andPer }}%</span>
                                </div>
                                <div v-if="type.value === 'O'">
                                  <span class="title font-italic">
                                    {{ targetTagsOrCount.toLocaleString() }}
                                  </span>
                                  <span class="subtitle-1">{{ orPer }}%</span>
                                </div>
                              </div>
                            </v-chip>
                          </div>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>타겟 태그</h3>
                <validation-provider v-slot="{ errors }" rules="required">
                  <v-autocomplete
                    v-model="rs3.targetTags"
                    :items="filteredTargetTags"
                    :error-messages="errors"
                    item-text="name"
                    item-value="id"
                    hide-no-data
                    clearable
                    deletable-chips
                    required
                    multiple
                    chips
                    placeholder="타겟을 적용할 태그를 선택하세요"
                    hide-selected
                    color="#FF007F"
                    return-object
                    prepend-inner-icon="mdi-tag-heart"
                    :search-input.sync="targetTagSearchInput"
                    @change="changeTargetTag"
                    :loading="targetTagLoading"
                    :disabled="targetTagLoading"
                    :disable-lookup="targetTagLoading"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        label
                        outlined
                        close
                        color="#FF007F"
                        @click="data.select"
                        @click:close="
                          () => {
                            removeTargetTag(data.item);
                          }
                        "
                      >
                        <v-icon left>
                          mdi-pound
                        </v-icon>
                        <span>{{ data.item.name }}</span>
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item === 'object'">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="`${data.item.name}`"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row>
          <v-col>
            <h3>수집 태그</h3>
            <validation-provider v-slot="{ errors }" rules="required">
              <v-autocomplete
                v-model="rs3.tags"
                :items="filteredTags"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                hide-no-data
                clearable
                deletable-chips
                required
                multiple
                chips
                placeholder="수집 태그를 선택하세요"
                hide-selected
                return-object
                prepend-inner-icon="mdi-tag"
                :search-input.sync="tagSearchInput"
                @change="tagSearchInput = ''"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    label
                    outlined
                    close
                    color="secondary"
                    @click="data.select"
                    @click:close="
                      () => {
                        remove(data.item);
                      }
                    "
                  >
                    <v-icon left>
                      mdi-pound
                    </v-icon>
                    <span>{{ data.item.name }}</span>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item === 'object'">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="`${data.item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="clickDetailTableBottomSheet" inset hide-overlay>
      <v-card
        flat
        height="600px"
        max-height="600px"
        width="100%"
        style="overflow-y:auto"
      >
        <v-toolbar flat dark>
          <v-toolbar-title>타겟 클릭 세부정보</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="clickDetailTableBottomSheet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" lg="6">
              <h3>AND 필터</h3>
              <Table
                class="table-striped-rows"
                :columns="clickColumns"
                :data-source="targetTagsAnd"
                size="small"
                :pagination="false"
                :rowKey="
                  (record, index) => {
                    return `and-${record.click}`;
                  }
                "
              >
                <template slot="localeString" slot-scope="value">
                  {{ value > 0 ? value.toLocaleString() : '-' }}
                </template>
                <template slot="percent" slot-scope="value">
                  {{ value }} %
                </template>
              </Table>
            </v-col>
            <v-col cols="12" lg="6">
              <h3>OR 필터</h3>
              <Table
                class="table-striped-rows"
                :columns="clickColumns"
                :data-source="targetTagsOr"
                size="small"
                :pagination="false"
                :rowKey="
                  (record, index) => {
                    return `or-${record.click}`;
                  }
                "
              >
                <template slot="localeString" slot-scope="value">
                  {{ value > 0 ? value.toLocaleString() : '-' }}
                </template>
                <template slot="percent" slot-scope="value">
                  {{ value }} %
                </template>
              </Table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import {
  TargetTagsTypeList,
  TargetTagsModeList
} from '@/config/admin/campaign';
import { mapGetters } from 'vuex';
import InformationHover from '@/components/InformationHover.vue';
import { Table } from 'ant-design-vue';
export default {
  components: {
    ValidationProvider,
    InformationHover,
    Table
  },
  data() {
    return {
      tagSearchInput: null,
      targetTagSearchInput: null,
      targetTagLoading: false,
      targetTagsAnd: [],
      targetTagsOr: [],
      tags: [],
      clickDetailTableBottomSheet: false,
      clickColumns: [
        {
          title: '클릭',
          dataIndex: 'click',
          key: 'click',
          align: 'right'
        },
        {
          title: '유저 수',
          dataIndex: 'length',
          key: 'length',
          align: 'right',
          scopedSlots: {
            customRender: 'localeString'
          }
        },
        {
          title: '비율 %',
          dataIndex: 'per',
          key: 'per',
          align: 'right',
          scopedSlots: {
            customRender: 'percent'
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'campaign/campaign'
    }),
    rs3() {
      return this.campaign.rs3;
    },
    TargetTagsTypeList() {
      return TargetTagsTypeList;
    },
    TargetTagsModeList() {
      return TargetTagsModeList;
    },
    filteredTargetTags() {
      return this.tags.filter(tag => {
        const tagIds = this.rs3.tags.map(v => v.id);
        return tagIds.includes(tag.id) === false;
      });
    },
    filteredTags() {
      return this.tags.filter(tag => {
        const targetTagIds = this.rs3.targetTags.map(v => v.id);
        return targetTagIds.includes(tag.id) === false;
      });
    },
    mediaGroupIds() {
      return this.campaign?.rs1?.user?.mediaGroups.map(v => v.id) || [];
    },
    targetTagsAndCount() {
      return this.targetTagsAnd
        .filter(v => v.click >= this.rs3.targetTagsClick)
        .reduce((acc, cur) => {
          return acc + cur.length;
        }, 0);
    },
    andPer() {
      return (
        (this.targetTagsAndCount /
          this.targetTagsAnd.reduce((acc, cur) => {
            return acc + cur.length;
          }, 0)) *
        100
      ).toFixed(2);
    },
    targetTagsOrCount() {
      return this.targetTagsOr
        .filter(v => v.click >= this.rs3.targetTagsClick)
        .reduce((acc, cur) => {
          return acc + cur.length;
        }, 0);
    },
    orPer() {
      return (
        (this.targetTagsOrCount /
          this.targetTagsOr.reduce((acc, cur) => {
            return acc + cur.length;
          }, 0)) *
        100
      ).toFixed(2);
    }
  },
  async created() {
    const { tags } = await this.getTagsApi();
    this.tags = tags;
  },
  mounted() {
    this.changeTargetTag();
  },
  methods: {
    async getTagsApi() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(`/admin/tags-campaign`);
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    remove(item) {
      const index = this.rs3.tags.map(v => v.id).indexOf(item.id);
      if (index >= 0) this.rs3.tags.splice(index, 1);
    },
    // 태그 타겟 삭제 이벤트시 targetTagsCount를 호출합니다.
    removeTargetTag(item) {
      const index = this.rs3.targetTags.map(v => v.id).indexOf(item.id);
      if (index >= 0) {
        this.rs3.targetTags.splice(index, 1);
      }
      this.changeTargetTag();
    },
    // targetTagsCount 를 업데이트 합니다.
    setTargetTagsCount(value) {
      if (value === 'A') {
        this.rs3.targetTagsCount = this.targetTagsAndCount;
      } else {
        this.rs3.targetTagsCount = this.targetTagsOrCount;
      }
    },
    // 태그 타겟 autocomplete change 이벤트입니다.
    async changeTargetTag() {
      // 검색 입력값 초기화
      this.targetTagSearchInput = '';

      // 선택된 태그 타겟의 길이가 0보다 클 경우 api 를 호출 합니다.
      if (this.rs3.targetTags.map(v => v.name).length > 0) {
        const { tag_and, tag_or } = await this.getTargetTagCountApi();
        this.targetTagsAnd = tag_and;
        this.targetTagsOr = tag_or;
      } else {
        this.targetTagsAnd = [];
        this.targetTagsOr = [];
      }

      this.setTargetTagsCount(this.rs3.targetTagsType);
    },
    async getTargetTagCountApi() {
      try {
        this.targetTagLoading = true;
        const { data } = await this.$axios.get(`/es/target-tag-count`, {
          params: {
            name: this.rs3.targetTags.map(v => v.name),
            range: this.rs3.targetTagsRange,
            mediaGroupIds: this.mediaGroupIds
          }
        });
        // console.log(data);
        return data;
      } catch (err) {
        console.error(err);
      } finally {
        this.targetTagLoading = false;
      }
    }
  }
};
</script>
